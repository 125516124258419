import type { GeoscienceObject } from '@api/goose/dist/enhancedGooseClient';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { ID_PARAM, WEBVIZ_BUTTON } from 'src/strings';
import { isObjectViewable } from 'src/utils/schemaUtils';
import { createNavigateToViewer } from 'src/utils/viewObjectUtils';

interface OpenInViewerButtonProps {
    readonly object: GeoscienceObject;
}

export function OpenInViewerButton({ object }: OpenInViewerButtonProps) {
    const featureFlags = useFlags();
    const { workspaceUuid, hubCode } = useParams();
    const [id] = usePersistedState(ID_PARAM);
    const navigate = useNavigate();

    if (!isObjectViewable(object, featureFlags) || !id || !workspaceUuid || !hubCode) {
        return null;
    }

    return (
        <Button
            automation-id="go-to-plot-buttons-view"
            onClick={() => {
                createNavigateToViewer(navigate, hubCode, workspaceUuid, id)();
            }}
            variant="contained"
            color="primary"
            fullWidth
            sx={{
                minHeight: '36px',
                maxHeight: '36px',
            }}
        >
            <Typography fontWeight={600} fontSize="13px">
                {WEBVIZ_BUTTON}
            </Typography>
        </Button>
    );
}
