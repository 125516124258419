import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
    recycleBinContentModeSelector,
    setRecycleBinContentMode,
} from 'src/store/features/displaySettingSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { FILES, GEOSCIENCE_OBJECTS_NICK } from 'src/strings';

export const RecycleBinTabs = () => {
    const dispatch = useAppDispatch();
    const recycleBinContentMode = useAppSelector(recycleBinContentModeSelector);

    return (
        <Grid>
            <Button
                variant="outlined"
                automation-id="recyclebin-objects"
                onClick={() => {
                    dispatch(setRecycleBinContentMode('objects'));
                }}
                sx={{
                    color: (theme) => theme.palette.text.primary,
                    borderColor: (theme) => theme.palette.grey[300],
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor:
                        recycleBinContentMode === 'objects'
                            ? (theme) => theme.palette.grey[200]
                            : 'transparent',
                    ':hover': {
                        borderColor: (theme) => theme.palette.grey[300],
                        backgroundColor:
                            recycleBinContentMode === 'objects'
                                ? (theme) => theme.palette.grey[200]
                                : (theme) => theme.palette.grey[100],
                    },
                }}
            >
                {GEOSCIENCE_OBJECTS_NICK}
            </Button>
            <Button
                variant="outlined"
                automation-id="recyclebin-files"
                onClick={() => {
                    dispatch(setRecycleBinContentMode('files'));
                }}
                sx={{
                    color: (theme) => theme.palette.text.primary,
                    borderColor: (theme) => theme.palette.grey[300],
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    backgroundColor:
                        recycleBinContentMode === 'files'
                            ? (theme) => theme.palette.grey[200]
                            : 'transparent',
                    ':hover': {
                        borderColor: (theme) => theme.palette.grey[300],
                        backgroundColor:
                            recycleBinContentMode === 'files'
                                ? (theme) => theme.palette.grey[200]
                                : (theme) => theme.palette.grey[100],
                    },
                }}
            >
                {FILES}
            </Button>
        </Grid>
    );
};
