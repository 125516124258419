import type { ListObjectsResponse } from '@api/goose/dist/enhancedGooseClient';
import { useListObjectsQuery, type ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { Order } from '@local/web-design-system-2/dist/components/GenericListing/types';
import type { HeadCell } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import { SortedList } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import TablePagination from '@mui/material/TablePagination';
import { useEffect } from 'react';

import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { SearchField } from 'src/components/searchField/SearchField';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import {
    ENTER_OBJECT_NAME,
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_OBJECTS,
    RECYCLE_BIN_NO_OBJECTS_DESC,
    SEARCH_LABEL,
} from 'src/strings';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { recycledObjectDefinition } from '../../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from '../RecycleBinPage.styles';
import { RecycleBinTabs } from '../recycleBinTabs';
import { RecycledObjectRow } from './RecycledObjectRow';

interface RecycleBinObjectContentProps {
    orgId: string;
    workspaceId: string;
    canRestore: boolean;
}
export interface ListedObjectDisplay extends ListedObject {
    depth: number;
}
interface ObjectListProps {
    data: ListObjectsResponse | undefined;
    isFetching: boolean;
    fields: FieldDefinition[];
    canRestore: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
}

const processRecycleObjectContents = (depth: number, contents?: ListedObject[]) => {
    if (!contents) {
        return [];
    }

    return contents.map(
        (object: ListedObject) =>
            ({
                ...object,
                depth,
            }) as ListedObjectDisplay,
    );
};

const ObjectList = ({ data, isFetching, fields, canRestore, handleSort }: ObjectListProps) => {
    const theme = useTheme();
    const { classes } = useStyles();

    const headCells: HeadCell<ListedObjectDisplay>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListedObjectDisplay,
            label: field.label,
            sortable: !!field.sortFunction,
            sx: field.sx,
        })),
        {
            id: 'extraHeader' as keyof ListedObjectDisplay,
            label: '',
            sortable: false,
            sx: { width: '10%' },
        },
    ];

    const processedObjects = processRecycleObjectContents(0, data?.objects);

    const renderRow = (object: ListedObjectDisplay) => (
        <RecycledObjectRow
            object={object}
            key={object.object_id}
            fields={fields}
            canRestore={canRestore}
        />
    );

    return (
        <SortedList
            data={processedObjects}
            headCells={headCells}
            renderRow={renderRow}
            defaultRowsPerPage={DEFAULT_PAGE_SIZE}
            isApiSort
            onSortChange={(key, newOrder) => {
                handleSort({
                    key: key as string,
                    order: newOrder === 'asc' ? Order.ASCENDING : Order.DESCENDING,
                });
            }}
            defaultOrderBy={
                fields.find((f) => f.defaultSortSettings)?.key as keyof ListedObjectDisplay
            }
            defaultOrder={
                fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                Order.ASCENDING
                    ? 'asc'
                    : 'desc'
            }
            containerSx={{
                table: { tableLayout: 'fixed' },
                height: 'calc(100vh - 320px)',
            }}
            EmptyComponent={
                <Grid container flexGrow={1} alignItems="center" justifyContent="center">
                    <EmptyState
                        title={RECYCLE_BIN_NO_OBJECTS}
                        titleSx={{ py: 1 }}
                        message={RECYCLE_BIN_NO_OBJECTS_DESC}
                        messageSx={{ color: theme.palette.grey[700], py: 1 }}
                        image={
                            <div className={classes.noItemsImage}>
                                <RecycleBinEmptySvg />
                            </div>
                        }
                        imageSx={{ py: 0 }}
                    />
                </Grid>
            }
            isLoading={isFetching}
            LoadingComponent={
                <PaginatedList
                    header={<TableHeader fields={fields} />}
                    content={<TableSkeleton rows={4} columns={fields.length} />}
                />
            }
        />
    );
};

export const RecycleBinObjectContent = ({
    orgId,
    workspaceId,
    canRestore,
}: RecycleBinObjectContentProps) => {
    const { classes } = useStyles();

    const { updateObjectSortParams, order, orderBy, key } = useObjectSortParams();

    const {
        page,
        pageSize,
        searchTerm,
        handleSearch,
        handleSetPage,
        handlePageSizeChange,
        setPaginationParams,
    } = usePagination();

    const fields = recycledObjectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const { data, isFetching, isError } = useListObjectsQuery({
        orgId,
        workspaceId,
        orderBy,
        deleted: true,
        offset: page * pageSize,
        limit: pageSize,
        ...(searchTerm !== '' && { objectName: [`ilike:*${searchTerm}*`] }),
    });

    const handleSort = (values: { key: string; order: Order }) => {
        if (order === values.order && key === values.key) {
            return;
        }
        updateObjectSortParams(values);
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [searchTerm]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    if (isError || (!isFetching && !data)) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Stack>
            <Stack
                direction="row"
                sx={{ paddingLeft: '16px' }}
                justifyContent="space-between"
                automation-id="pagination-menu"
            >
                <TablePagination
                    page={page}
                    component="div"
                    rowsPerPage={pageSize}
                    labelRowsPerPage="Show"
                    count={data?.total ?? 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    classes={{ toolbar: classes.pagination }}
                    sx={{
                        fontSize: '10px',
                        '.MuiTablePagination-displayedRows': {
                            fontSize: '10px',
                        },
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'start',
                    }}
                />
                <Grid container justifyContent="end" gap={3}>
                    <RecycleBinTabs />
                    <SearchField
                        color="secondary"
                        variant="outlined"
                        defaultValue={searchTerm}
                        sx={{ maxWidth: '220px' }}
                        onSearchCallBack={handleSearch}
                        placeholder={ENTER_OBJECT_NAME}
                        automation-id="recyclebin-search"
                        InputProps={{ sx: { maxHeight: '40px' } }}
                        userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                        userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                        label={SEARCH_LABEL}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Stack>
            <ObjectList
                data={data}
                isFetching={isFetching}
                fields={fields}
                canRestore={canRestore}
                handleSort={handleSort}
            />
        </Stack>
    );
};
