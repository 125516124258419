import type { GetObjectResponse, OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useLazyGetObjectByIdQuery } from '@api/goose/dist/enhancedGooseClient';
import Blocksync from '@local/web-design-system-2/dist/icons/Blocksync';
import Delete from '@local/web-design-system-2/dist/icons/Delete';
import Driver from '@local/web-design-system-2/dist/icons/Driver';
import OpenInViewer from '@local/web-design-system-2/dist/icons/OpenInViewer';
import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import { useGetCurrentUserRoleQuery } from '@local/workspaces/src/apiClients/enhancedWorkspaceMiddleware';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    OPEN_IN_BLOCK_SYNC_ACTION,
    OPEN_IN_DRIVER_ACTION,
    OPEN_IN_VIEWER_ACTION,
    RECYCLE_OBJECT_ACTION,
} from 'src/strings';
import { isObjectViewable } from 'src/utils/schemaUtils';
import {
    canOpenInBlockSync,
    canOpenInDriver,
    canOpenSchemaInBlockSync,
    createNavigateToBlockSync,
    createNavigateToDriver,
    createNavigateToViewer,
} from 'src/utils/viewObjectUtils';

import type { ListedObjectDisplay } from './utils';

interface ObjectActionProps {
    object: OrgListedObject | ListedObjectDisplay;
    onRecycle: () => void;
}

interface MenuItemConfig {
    key: string | number;
    ItemComponent: React.ComponentType<any>;
    action: () => void;
}

interface DynamicMenuItemConfig extends MenuItemConfig {
    // Whether or not the option itself should be eventually rendered
    shouldRender: boolean;
    // Whether or not we show a skeleton for this option while loading.
    // Used in conjunction with shouldRender to determine if a fetch is needed.
    // If an option should show a skeleton but should not currently be rendered it means a data fetch is needed.
    showSkeleton: boolean;
    // Whether we should show the option as disabled if shouldRender is true.
    isDisabled: boolean;
}

function RecycleAction() {
    return (
        <Grid
            container
            alignItems="center"
            spacing={2}
            wrap="nowrap"
            automation-id="object-row-delete-action"
        >
            <Grid item>
                <Delete viewBox="0 0 24 20" />
            </Grid>
            <Grid item>{RECYCLE_OBJECT_ACTION}</Grid>
        </Grid>
    );
}

function OpenInDriverAction() {
    return (
        <Grid
            container
            alignItems="center"
            spacing={2}
            wrap="nowrap"
            automation-id="object-row-open-in-driver-action"
        >
            <Grid item>
                <Driver viewBox="0 0 24 20" />
            </Grid>
            <Grid item>{OPEN_IN_DRIVER_ACTION}</Grid>
        </Grid>
    );
}

function OpenInViewerAction() {
    return (
        <Grid
            container
            alignItems="center"
            spacing={2}
            wrap="nowrap"
            automation-id="object-row-open-in-viewer-action"
        >
            <Grid item>
                <OpenInViewer viewBox="0 0 24 20" color="secondary" />
            </Grid>
            <Grid item>{OPEN_IN_VIEWER_ACTION}</Grid>
        </Grid>
    );
}

function OpenInBlockSyncAction() {
    return (
        <Grid
            container
            alignItems="center"
            spacing={2}
            wrap="nowrap"
            automation-id="object-row-open-in-blocksync-action"
        >
            <Grid item>
                <Blocksync viewBox="0 0 24 20" />
            </Grid>
            <Grid item>{OPEN_IN_BLOCK_SYNC_ACTION}</Grid>
        </Grid>
    );
}

function SkeletonMenuOption() {
    // Wrap Block sync action to allow skeleton to infer dimensions of the largest option and only option that requires loading.
    return (
        <Grid automation-id="object-row-open-in-skeleton-action">
            <Skeleton animation="wave" variant="rounded">
                <OpenInBlockSyncAction />
            </Skeleton>
        </Grid>
    );
}

export function ObjectActions({ object, onRecycle }: ObjectActionProps) {
    const theme = useTheme();
    const featureFlags = useFlags();
    const { evouiEnableDelete } = featureFlags;
    const { hostname } = window.location;
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const navigate = useNavigate();
    const [objectDetail, setObjectDetail] = useState<GetObjectResponse | undefined>(undefined);
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const params = useParams();
    const { data: currentUserRoleData } = useGetCurrentUserRoleQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });
    const canDelete = hasRoleOrHigher(currentUserRoleData?.role ?? null, 'editor');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        handleMenuOpen();
    };

    async function handleMenuOpen() {
        if (!orgUuid || !workspaceUuid || !shouldFetch) {
            return;
        }

        const gooseResponse = await getObjectByIdTrigger(
            {
                objectId: object.object_id,
                orgId: orgUuid,
                workspaceId: workspaceUuid,
            },
            true, // Cache query.
        ).unwrap();
        setObjectDetail(gooseResponse);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRecycle = () => {
        handleClose();
        onRecycle();
    };

    if (!orgUuid || !workspaceUuid || !hubCode) {
        return null;
    }

    const actionOptions: DynamicMenuItemConfig[] = [
        {
            key: 'openInViewer',
            action: createNavigateToViewer(navigate, hubCode, workspaceUuid, object.object_id),
            ItemComponent: OpenInViewerAction,
            shouldRender: isObjectViewable(objectDetail?.object, featureFlags),
            showSkeleton: isObjectViewable(objectDetail?.object, featureFlags),
            isDisabled: false,
        },
        {
            key: 'openInBlockSync',
            action: objectDetail
                ? createNavigateToBlockSync(
                      hostname,
                      orgUuid,
                      workspaceUuid,
                      hubCode,
                      objectDetail.object.blocksync_uuid,
                  )
                : () => {},
            ItemComponent: OpenInBlockSyncAction,
            shouldRender: canOpenInBlockSync(objectDetail, featureFlags),
            showSkeleton: canOpenSchemaInBlockSync(object.schema, featureFlags),
            isDisabled: false,
        },
        {
            key: 'openInDriver',
            action: createNavigateToDriver(
                hostname,
                orgUuid,
                hubCode,
                workspaceUuid,
                object.object_id,
            ),
            ItemComponent: OpenInDriverAction,
            shouldRender: canOpenInDriver(object.schema, featureFlags),
            showSkeleton: canOpenInDriver(object.schema, featureFlags),
            isDisabled: false,
        },
        {
            key: 'recycle',
            action: handleRecycle,
            ItemComponent: RecycleAction,
            shouldRender: evouiEnableDelete,
            showSkeleton: evouiEnableDelete,
            isDisabled: !canDelete,
        },
    ];

    // Calculate the number of skeleton options based on showSkeleton
    const skeletonCount = actionOptions.filter((option) => option.showSkeleton).length;

    const skeletonOptions: MenuItemConfig[] = Array.from({ length: skeletonCount }, (_, index) => ({
        key: `skeleton-${index}`,
        action: () => {},
        ItemComponent: SkeletonMenuOption,
    }));

    const optionsToRender = actionOptions.filter((option) => option.shouldRender);

    // If all skeleton objects are already set to be rendered there is no need to load a detail response to render specific options.
    const shouldFetch = skeletonCount !== optionsToRender.length;

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton
                    size="large"
                    onClick={handleClick}
                    automation-id="overflow-icon"
                    sx={{ color: theme.palette.text.secondary }}
                >
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {!(objectDetail || !shouldFetch) &&
                    skeletonOptions.map((option) => (
                        <MenuItem key={option.key}>
                            <SkeletonMenuOption />
                        </MenuItem>
                    ))}
                {(objectDetail || !shouldFetch) &&
                    [...actionOptions].map((option) => {
                        const { key, ItemComponent, action, shouldRender } = option;
                        if (!shouldRender) {
                            return null;
                        }
                        return (
                            <MenuItem
                                key={key}
                                onClick={() => action()}
                                disabled={option.isDisabled}
                                automation-id={`action-menu-item-${key}`}
                            >
                                <ItemComponent />
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
}
