/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export interface DisplaySettingsState {
    workspaceContentMode: 'path' | 'affinity-folders';
    recycleBinContentMode?: 'objects' | 'files';
    currentView?: string;
}

const initialState: DisplaySettingsState = {
    workspaceContentMode: 'path',
    recycleBinContentMode: 'objects',
    currentView: undefined,
};

export const displaySettingsSlice = createSlice({
    name: 'displaySettings',
    initialState,
    reducers: {
        setWorkspaceContentMode: (
            state: DisplaySettingsState,
            action: PayloadAction<'path' | 'affinity-folders'>,
        ) => {
            state.workspaceContentMode = action.payload;
        },
        setRecycleBinContentMode: (
            state: DisplaySettingsState,
            action: PayloadAction<'objects' | 'files'>,
        ) => {
            state.recycleBinContentMode = action.payload;
        },
        setCurrentView: (state: DisplaySettingsState, action: PayloadAction<string>) => {
            state.currentView = action.payload;
        },
    },
});

const displaySettingsState = (state: RootState) => state.displaySettings;

export const workspaceContentModeSelector: any = createSelector(
    [displaySettingsState],
    (settingsState: DisplaySettingsState) => settingsState.workspaceContentMode,
);

export const recycleBinContentModeSelector: any = createSelector(
    [displaySettingsState],
    (settingsState: DisplaySettingsState) => settingsState.recycleBinContentMode,
);

export const currentViewSelector: any = createSelector(
    [displaySettingsState],
    (settingsState: DisplaySettingsState) => settingsState.currentView,
);

export const { setWorkspaceContentMode, setRecycleBinContentMode, setCurrentView } =
    displaySettingsSlice.actions;
