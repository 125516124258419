import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

import { USER_DETAILS, WORKSPACES_AND_PERMISSIONS } from '../../strings';
import { useStyles } from './UserContentContainer.styles';

export interface UserContentContainerProps {
    columnContent: ReactNode;
    panelContent?: ReactNode;
}

export const UserContentContainer = ({
    columnContent,
    panelContent,
}: UserContentContainerProps) => {
    const { classes } = useStyles();

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                md={12}
                lg={3}
                xl={4}
                className={classes.formColumn}
                container
                wrap="nowrap"
                direction="column"
            >
                <Grid item xs={12} className={classes.headerRow} display="flex" alignItems="center">
                    <Typography variant="body2" color="inherit">
                        {USER_DETAILS}
                    </Typography>
                </Grid>
                <Grid container alignContent="flex-start">
                    {columnContent}
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                lg={9}
                xl={8}
                style={{ height: '100%' }}
                container
                wrap="nowrap"
                direction="column"
                className={classes.permissionContainer}
            >
                <Grid item xs={12} className={classes.headerRow} display="flex" alignItems="center">
                    <Typography variant="body2" color="inherit">
                        {WORKSPACES_AND_PERMISSIONS}
                    </Typography>
                </Grid>
                {panelContent}
            </Grid>
        </Grid>
    );
};
