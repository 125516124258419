import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import {
    useLazyGetObjectByIdQuery,
    useLazyListObjectsQuery,
} from '@api/goose/dist/enhancedGooseClient';
import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import type { XyzInstanceContextValue } from '@local/webviz/dist/context/createXyzInstanceContext';
import {
    createXyzInstanceContext,
    XyzContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import type { XyzInstance } from '@local/webviz/dist/types';
import { useGetWorkspaceQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import merge from 'lodash-es/merge';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import { clearSceneState } from 'src/store/visualization/visualizationSlice';
import {
    WEBVIZ_VIEWER_BREADCRUMB,
    workspaceListingWithLinkBreadcrumb,
    workspacesPath,
} from 'src/utils/breadcrumbs';
import { extractSchema, isObjectViewable } from 'src/utils/schemaUtils';

import { useObjectFilterParams } from '../../hooks/useObjectFilterParams';
import { useObjectSearchParams } from '../../hooks/useObjectSearchParams';
import { NETWORK_ERROR_DESCR, NETWORK_ERROR_TITLE } from '../../strings';
import { LOAD_SIZE, Schemas } from '../constants';
import { ObjectsPanel } from '../ObjectsPanel/ObjectsPanel';
import { SkeletonObjectsPanelContents } from '../ObjectsPanel/ProjectTree/ProjectTreePanel';
import { Plot } from '../Plot/Plot';

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    const params = useParams();
    const { objectName } = useObjectSearchParams();
    const { filters } = useObjectFilterParams();
    const [getListObjectsTrigger, { isError, isSuccess, isFetching }] = useLazyListObjectsQuery();
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const flags = useFlags();
    const dispatch = useAppDispatch();
    const [gooseObjects, setGooseObjects] = useState<ListedObject[]>([]);
    const { data, isLoading } = useGetWorkspaceQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });

    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [
        ...workspaceListingWithLinkBreadcrumb(params),
        {
            name: data?.name ?? '',
            path: `${workspacesPath(params)}/${params.hubCode}/${
                params.workspaceUuid
            }/${WORKSPACES_OVERVIEW_PAGE}`,
        },
        { name: WEBVIZ_VIEWER_BREADCRUMB },
    ];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, [isLoading]);

    useEffect(() => {
        async function fetchGooseData() {
            try {
                const initialResponse = await getListObjectsTrigger(
                    {
                        orgId: getOrgUuidFromParams(params),
                        workspaceId: getSelectedWorkspaceFromParams(params),
                        ...filters,
                        objectName,
                        offset: 0,
                        limit: LOAD_SIZE,
                    },
                    true,
                ).unwrap();

                const totalObjects = initialResponse.total ?? 0;
                const numberOfRequests = Math.ceil(totalObjects / LOAD_SIZE) - 1;
                const promiseList = Array.from({ length: numberOfRequests }, (_, index) =>
                    getListObjectsTrigger(
                        {
                            orgId: getOrgUuidFromParams(params),
                            workspaceId: getSelectedWorkspaceFromParams(params),
                            ...filters,
                            objectName,
                            offset: (index + 1) * LOAD_SIZE,
                            limit: LOAD_SIZE,
                        },
                        true,
                    ).unwrap(),
                );

                const responses = await Promise.allSettled(promiseList);

                const objects = responses.reduce<ListedObject[]>((acc, response) => {
                    if (response.status === 'fulfilled') {
                        return [...acc, ...response.value.objects];
                    }
                    trackError('Error loading goose objects for visualization');
                    return acc;
                }, initialResponse.objects);
                const blockmodelObjects: ListedObject[] = [];
                objects.forEach((object) => {
                    const schema = extractSchema(object.schema);
                    if (schema === Schemas.BlockSyncReferenceSchema) {
                        blockmodelObjects.push(object);
                    }
                });
                const blockmodelPromises = blockmodelObjects.map(async (object) => {
                    try {
                        const response = await getObjectByIdTrigger(
                            {
                                objectId: object.object_id,
                                orgId: getOrgUuidFromParams(params),
                                workspaceId: getSelectedWorkspaceFromParams(params),
                            },
                            true,
                        ).unwrap();
                        return merge({}, response.object, object);
                    } catch (error) {
                        trackError('Error loading goose blockmodel object', JSON.stringify(error));
                        return object;
                    }
                });
                const updatedBlockmodelObjects = await Promise.allSettled(blockmodelPromises).then(
                    (results) =>
                        results
                            .filter((result) => result.status === 'fulfilled')
                            .map((result) => result.value),
                );

                const viewableObjects = objects.filter((object: ListedObject) => {
                    const schema = extractSchema(object.schema);
                    if (schema === Schemas.BlockSyncReferenceSchema) {
                        const foundBlockmodel = updatedBlockmodelObjects.find(
                            (blockmodel) => blockmodel.object_id === object.object_id,
                        );
                        return foundBlockmodel && isObjectViewable(foundBlockmodel, flags);
                    }
                    return isObjectViewable(object, flags);
                });
                setGooseObjects(viewableObjects);
            } catch (error) {
                trackError('Error loading goose objects for visualization', JSON.stringify(error));
            }
        }
        fetchGooseData();
        return () => {
            dispatch(clearSceneState());
        };
    }, []);

    const projectTree = (
        <Grid item xs zIndex={1}>
            {xyzInstanceInitialized && isSuccess && <ObjectsPanel data={gooseObjects} />}
            {xyzInstanceInitialized && isFetching && <SkeletonObjectsPanelContents />}
        </Grid>
    );

    const plot = (
        <Plot
            initialized={xyzInstanceInitialized}
            onInitialized={(xyzInstance: XyzInstance) =>
                setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
            }
        />
    );

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            <SplitLayout leftPanelComponent={projectTree} rightPanelComponent={plot} />
        </XyzContext.Provider>
    );
}
