import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import Folder from '@local/web-design-system-2/dist/icons/Folder';
import FolderOpen from '@local/web-design-system-2/dist/icons/FolderOpen';
import { OverflowTooltip } from '@local/web-design-system/dist/components/OverflowTooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SchemaIcon } from './SchemaIcon';
import { fileNameExtensionRemover } from './utils';

export const EmptyCell = () => <Typography variant="body2" />;

export const FolderCell = ({ item, itemKey }: ListCellComponentProps) => {
    const folderName = item[itemKey];
    return (
        <Grid direction="row" container style={{ paddingLeft: `${item.depth * 30}px` }}>
            {item.isOpen ? <FolderOpen /> : <Folder />}
            <Typography variant="body2" style={{ paddingLeft: '10px', lineHeight: '28px' }}>
                {folderName}
            </Typography>
        </Grid>
    );
};

export const FileCell = ({ item }: ListCellComponentProps) => {
    const { schema, name } = item;
    const Icon = SchemaIcon(schema);
    return (
        <Grid
            direction="row"
            container
            style={{ paddingLeft: `${item.depth * 30}px`, flexWrap: 'nowrap' }}
        >
            <div style={{ width: 24 }}>
                <Icon />
            </div>
            <Typography
                variant="body2"
                style={{
                    width: '100%',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    lineHeight: '28px',
                }}
            >
                <OverflowTooltip title={name} maxLines={2}>
                    {fileNameExtensionRemover(name)}
                </OverflowTooltip>
            </Typography>
        </Grid>
    );
};

export const StageCell = ({ item }: ListCellComponentProps) => (
    <Grid direction="row" container style={{ paddingLeft: `${item.depth * 30}px` }}>
        <Typography variant="body2" style={{ lineHeight: '28px' }}>
            {item.stage?.name || ''}
        </Typography>
    </Grid>
);
